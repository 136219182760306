import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
        },
        input: {
          padding: '7px 0px',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: '#B9B5B6',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          backgroundColor: 'white',
          minWidth: '350px',
        },
      },
    },
  },
});

DefaultInput.propTypes = {
  icon: PropTypes.element,
};

export default function DefaultInput({ icon, ...props }) {
  return (
    <ThemeProvider theme={theme}>
      <TextField
        {...props}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {icon ? icon : <SearchIcon />}
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
    </ThemeProvider>
  );
}
