import { useEffect, useState } from 'react';

import DashboardCard from './DashboardCard';

import { getCondoOccupancyPercentage } from '../../../api/dashboard';

export function AverageOccupancy() {
  const [cardData, setCardData] = useState('');

  useEffect(() => {
    getCondoOccupancyPercentage().then(response => {
      setCardData(response.result);
    });
  }, []);

  return (
    <div>
      <DashboardCard
        data={`${cardData}%`}
        title="Porcentagem de ocupação"
        subtitle="dos armários"
      />
    </div>
  );
}
