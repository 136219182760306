import { Button, Modal, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { colors } from '../../../styles/colors';

ModalAdminPasscodes.propTypes = {
  openModalAdmin: PropTypes.bool,
  handleCloseModalAdmin: PropTypes.func,
  handleResetAdminPasscode: PropTypes.func,
};

export default function ModalAdminPasscodes({
  openModalAdmin,
  handleCloseModalAdmin,
  handleResetAdminPasscode,
}) {
  return (
    <Modal
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={openModalAdmin}
      onClose={handleCloseModalAdmin}
      aria-labelledby="modal-title"
      aria-describedby="modal-description">
      <div
        style={{
          backgroundColor: 'white',
          minWidth: '200px',
          minHeight: '200px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'space-around',
          padding: '10px',
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            marginTop: '10px',
            maxWidth: '300px',
            textAlign: 'center',
            alignItems: 'center',
            height: '200px',
          }}>
          <Typography
            style={{
              fontWeight: 600,
              color: colors.tertiary_40,
              fontFamily: 'Montserrat',
              fontSize: '1.2rem',
            }}>
            Deseja atualizar todas as senhas de administrador deste armário?
          </Typography>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <Button
              onClick={() => {
                handleResetAdminPasscode();
                handleCloseModalAdmin();
              }}
              variant="contained"
              style={{
                backgroundColor: colors.tertiary_40,
                color: 'white',
                maxWidth: '300px',
              }}>
              Atualizar senhas
            </Button>
            <Button
              onClick={() => handleCloseModalAdmin()}
              variant="contained"
              style={{
                backgroundColor: colors.white,
                color: colors.tertiary_40,
                border: `1px solid ${colors.tertiary_40}`,
                marginTop: '10px',
                maxWidth: '100px',
              }}>
              Fechar
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
