import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import { getAllCompanies, loginAsCompany } from '../../api/administrator';
import Toast from '../../components/Toast';

Companies.propTypes = {
  handler: PropTypes.func,
};

export default function Companies({ handler }) {
  const toastRef = useRef();
  const [companies, setCompanies] = useState([]);
  const [countCompanies, setCountCompanies] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [direction, setDirection] = useState('ASC');
  const [param, setParam] = useState('id');

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const openInNewTab = (token, company) => {
    localStorage.setItem('token', JSON.stringify('Bearer ' + token));
    localStorage.setItem('type', JSON.stringify('company'));
    localStorage.setItem('condoCompany', JSON.stringify(company));
    localStorage.setItem('adminType', 'Lockin');
    window.open('/', '_blank', 'noopener,noreferrer');
  };

  const loginCompany = company => {
    loginAsCompany(company.id).then(response => {
      if (response.status === 200) {
        openInNewTab(response.result, company);
      } else {
        toastRef.current.handleOpen('Erro ao acessar a empresa.', 'error');
      }
    });
  };

  const handleOrdering = paramInput => {
    setParam(paramInput);
    direction === 'ASC' ? setDirection('DESC') : setDirection('ASC');
  };

  useEffect(() => {
    async function fetchCompanies() {
      try {
        const response = await getAllCompanies(
          `${param} ${direction}`,
          page + 1,
          rowsPerPage,
        );
        setCompanies(response.result.companies);
        setCountCompanies(response.result.total);
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    }
    fetchCompanies();
  }, [page, rowsPerPage, direction, param]);

  useEffect(() => {
    handler('Todas Empresas');
    getAllCompanies('id ASC', 0, 10).then(response => {
      setCompanies(response.result.companies);
      setCountCompanies(response.result.total);
    });
  }, [handler]);

  return (
    <div
      style={{
        backgroundColor: 'white',
        minHeight: '300px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-around',
        padding: '10px',
      }}>
      <Toast ref={toastRef} />

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 600 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Button
                  type="button"
                  onClick={() => handleOrdering('id')}
                  style={{ fontWeight: 'bold' }}>
                  ID
                </Button>
              </TableCell>
              <TableCell align="center">
                <Button
                  type="button"
                  onClick={() => handleOrdering('name')}
                  style={{ fontWeight: 'bold' }}>
                  Nome
                </Button>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies !== '' ? (
              companies.map(company => (
                <TableRow
                  key={company.id}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}>
                  <TableCell align="center">{company.id}</TableCell>
                  <TableCell align="center">{company.name}</TableCell>
                  <TableCell align="center">
                    <Button
                      onClick={() => {
                        loginCompany(company);
                      }}
                      variant="contained"
                      style={{
                        backgroundColor: '#2196f3 ',
                        color: 'white',
                        margin: '10px',
                      }}>
                      Login
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <></>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25]}
          count={countCompanies}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={'Empresas por página'}
        />
      </TableContainer>
    </div>
  );
}
