import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Modal,
  NativeSelect,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import MuiPhoneNumber from 'material-ui-phone-number';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import Geocode from 'react-geocode';

import DeliveryDetails from './components/DeliveryDetails';
import DeliveryStatus from './components/DeliveryStatus';

import {
  createDelivery,
  getCompanyDeliveries,
  getControllersPerLocation,
  getDeliveryDetails,
} from '../../api/delivery';
import Toast from '../../components/Toast';
import { colors } from '../../styles/colors';
import { cepMask } from '../../utils/cepMask';

Geocode.setApiKey(process.env.REACT_APP_GEOCODE_API_KEY);
Geocode.setLanguage('pt-BR');
Geocode.setRegion('br');
Geocode.setLocationType('ROOFTOP');

Delivery.propTypes = {
  handler: PropTypes.func,
};

export default function Delivery(props) {
  const toastRef = useRef();
  const [address, setAddress] = useState('');
  const [controllers, setControllers] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientCellphone, setClientCellphone] = useState('');
  const [detailsModal, setDetailsModal] = useState(false);
  const [deliveries, setDeliveries] = useState('');
  const [deliveriesTable, setDeliveriesTable] = useState('');
  const [deliveryDetails, setDeliveryDetails] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedController, setSelectedController] = useState('');
  const [sizeSelectData, setSizeSelectData] = useState('');
  const [sizeSelected, setSizeSelected] = useState('');
  const [totalDeliveries, setTotalDeliveries] = useState(0);
  const [zipCode, setZipCode] = useState('');

  const TableHeader = withStyles(() => ({
    root: {
      fontWeight: 'bold',
      backgroundColor: '#d2cfd1',
      height: '50px',
    },
  }))(TableCell);

  const getGeolocation = async address => {
    return await Geocode.fromAddress(address)
      .then(response => {
        return response.results[0];
      })
      .catch(error => {
        console.log(error);
      });
  };

  const getNearbyControllers = async () => {
    const geolocationResponse =
      (await getGeolocation(zipCode)) || (await getGeolocation(address));
    if (geolocationResponse) {
      const state = geolocationResponse.address_components.find(component => {
        return component.types[0] === 'administrative_area_level_1';
      });
      if (state === null) {
        toastRef.current.handleOpen(
          'Erro ao buscar armários próximos.',
          'error',
        );
        return;
      }
      const response = await getControllersPerLocation(
        geolocationResponse.geometry.location.lat,
        geolocationResponse.geometry.location.lng,
        state.short_name,
      );
      if (response.status === 200) {
        setControllers(response.controllers);
      } else {
        toastRef.current.handleOpen(
          'Erro ao buscar armários próximos.',
          'error',
        );
      }
    } else {
      toastRef.current.handleOpen('Erro ao buscar armários próximos.', 'error');
    }
  };

  const handleChangeClientCellphone = cellphone => {
    setClientCellphone(
      cellphone.replace('(', '').replace(')', '').replace(/\s+/g, ''),
    );
  };

  const handleChangeClientEmail = email => {
    setClientEmail(email.target.value);
  };

  const handleChangeZipCode = code => {
    setZipCode(cepMask(code));
  };

  const handleChangeAddress = addressInput => {
    setAddress(addressInput);
  };

  const getSizeSelectData = async locks => {
    let sizes = [];
    await Promise.all(
      locks.map(async lock => {
        let sizeString = `${lock.size} (${lock.dimension})`;
        if (!sizes.includes(sizeString)) {
          sizes.push(sizeString);
        }
      }),
    );
    setSizeSelectData(sizes);
  };

  const handleChangeController = controllerInput => {
    setSelectedController(controllerInput);
    getSizeSelectData(JSON.parse(controllerInput).locks);
  };

  const getDeliveries = async (page = 0, rowsPerPage = 10) => {
    const offset = page + 1,
      limit = rowsPerPage;
    const companyDeliveries = await getCompanyDeliveries(limit, offset);
    let newDeliveriesTable = [];
    setDeliveries(companyDeliveries.data.delivery);
    setTotalDeliveries(companyDeliveries.data.total);
    if (companyDeliveries.length > 0) {
      for (let index = 0; index < 5; index++) {
        newDeliveriesTable[index] = companyDeliveries.data.delivery[index];
      }
    }
    setDeliveriesTable(newDeliveriesTable);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getDeliveries(newPage, rowsPerPage);
  };

  const handleChangeSize = sizeInput => {
    setSizeSelected(sizeInput);
  };

  const handleChangeRowsPerPage = rowsPerPageInput => {
    setRowsPerPage(parseInt(rowsPerPageInput, 10));
    setPage(0);
    getDeliveries(0, parseInt(rowsPerPageInput, 10));
  };

  const openDetailsModal = async deliveryId => {
    const response = await getDeliveryDetails(deliveryId);
    if (response.status === 200) {
      setDeliveryDetails(response.data.response);
      setDetailsModal(true);
    } else {
      toastRef.current.handleOpen(
        'Erro ao recuperar detalhes da entrega.',
        'error',
      );
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setAddress('');
    setZipCode('');
    setClientEmail('');
    setClientCellphone('');
    setSelectedController('');
    setControllers('');
    setSizeSelectData('');
    setSizeSelected('');
  };

  const handleCreateDelivery = async () => {
    const response = await createDelivery(
      JSON.parse(selectedController).controller.id,
      sizeSelected.split(' ')[0],
      clientEmail,
      clientCellphone,
    );
    if (response.status === 200) {
      toastRef.current.handleOpen('Entrega criada com sucesso!', 'success');
      await getDeliveries()
        .then(() => {
          closeModal();
          openDetailsModal(deliveries[0].id);
        })
        .catch(() => {
          toastRef.current.handleOpen(
            'Erro ao carregar detalhes da entrega.',
            'error',
          );
        });
    } else {
      toastRef.current.handleOpen('Erro ao criar entrega.', 'error');
    }
  };

  useEffect(() => {
    props.handler('Entregas');
    getDeliveries();
  }, [props]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
      <Toast ref={toastRef} />

      <Modal
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={detailsModal}
        onClose={() => setDetailsModal(false)}>
        <DeliveryDetails details={deliveryDetails} />
      </Modal>

      <Modal
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={modalOpen}
        onClose={closeModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description">
        <div
          style={{
            backgroundColor: 'white',
            minWidth: '500px',
            minHeight: '600px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'space-around',
            padding: '10px',
          }}>
          <div style={{ flexDirection: 'row' }}>
            <MuiPhoneNumber
              style={{ marginRight: 10 }}
              variant="outlined"
              label="Celular do cliente"
              defaultCountry={'br'}
              value={clientCellphone}
              onChange={handleChangeClientCellphone}
            />
            <TextField
              label="Email do cliente"
              variant="outlined"
              type="email"
              value={clientEmail}
              onChange={handleChangeClientEmail}
            />
          </div>
          <div style={{ flexDirection: 'row' }}>
            <TextField
              style={{ marginRight: 10 }}
              label="CEP"
              variant="outlined"
              value={zipCode}
              onChange={event => handleChangeZipCode(event.target.value)}
            />
            <TextField
              label="Endereço"
              variant="outlined"
              value={address}
              onChange={event => handleChangeAddress(event.target.value)}
            />
          </div>
          <Button
            onClick={() => {
              getNearbyControllers();
            }}
            variant="contained"
            style={{
              backgroundColor: colors.blue,
              color: 'white',
              marginRight: '10px',
            }}
            disabled={zipCode === '' || address === ''}>
            Ver armários próximos ao endereço
          </Button>
          {controllers ? (
            <>
              <RadioGroup
                aria-label="quiz"
                name="quiz"
                value={selectedController}
                onChange={event => handleChangeController(event.target.value)}>
                <FormHelperText style={{ textAlign: 'center' }}>
                  Escolha um armário
                </FormHelperText>
                {controllers.length === 0 ? (
                  <>
                    Não foram encontrados armários disponíveis para sua região
                  </>
                ) : (
                  <>
                    {controllers.map(controller => (
                      <FormControlLabel
                        key={controller.id}
                        value={JSON.stringify(controller)}
                        control={<Radio />}
                        label={controller.address.address_line}
                      />
                    ))}
                  </>
                )}
              </RadioGroup>
              {selectedController && sizeSelectData ? (
                <>
                  {sizeSelectData.length > 0 ? (
                    <FormControl minWidth={120}>
                      <InputLabel>Tamanho da porta</InputLabel>
                      <NativeSelect
                        value={sizeSelected}
                        onChange={event =>
                          handleChangeSize(event.target.value)
                        }>
                        <option aria-label="None" value="" />
                        {sizeSelectData.map(size => (
                          <option
                            key={size}
                            style={{
                              textAlign: 'center',
                            }}
                            value={size}>
                            {size}
                          </option>
                        ))}
                      </NativeSelect>
                      <FormHelperText>
                        Escolha um tamanho de porta disponível
                      </FormHelperText>
                    </FormControl>
                  ) : (
                    <>
                      {' '}
                      <Typography>
                        Não há portas disponíveis para esse armário no momento
                      </Typography>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              marginTop: '10px',
            }}>
            <Button
              onClick={() => {
                closeModal();
              }}
              variant="contained"
              style={{
                backgroundColor: colors.red,
                color: 'white',
                marginRight: '10px',
              }}>
              Fechar
            </Button>

            <Button
              disabled={
                !(
                  sizeSelected &&
                  selectedController &&
                  clientCellphone &&
                  clientEmail
                )
              }
              onClick={() => {
                handleCreateDelivery();
              }}
              variant="contained"
              style={{
                backgroundColor: 'green',
                color: 'white',
              }}>
              Criar pedido de entrega
            </Button>
          </div>
        </div>
      </Modal>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}>
        {deliveriesTable !== '' && deliveries !== undefined ? (
          <>
            {
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableHeader>Identificador da entrega</TableHeader>
                      <TableHeader>Telefone do cliente</TableHeader>
                      <TableHeader>Email do cliente</TableHeader>
                      <TableHeader>Status da entrega</TableHeader>
                      <TableHeader>Data de criação</TableHeader>
                      <TableHeader></TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {deliveries.map(delivery => (
                      <TableRow key={delivery.id}>
                        <TableCell>{delivery.id}</TableCell>
                        <TableCell>{delivery.client_cellphone}</TableCell>
                        <TableCell>{delivery.client_email}</TableCell>
                        <TableCell>
                          <DeliveryStatus status={delivery.status} />
                        </TableCell>
                        <TableCell>
                          {new Date(delivery.created_datetime).toLocaleString(
                            'pt-br',
                          )}
                        </TableCell>
                        <TableCell>
                          <Button
                            onClick={() => {
                              openDetailsModal(delivery.id);
                            }}
                            variant="contained"
                            style={{
                              backgroundColor: colors.blue,
                              color: 'white',
                              marginRight: '20px',
                              height: '45px',
                              width: '100px',
                              fontSize: '10px',
                              fontWeight: 'bold',
                              padding: '15px',
                            }}>
                            Detalhes da entrega
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={totalDeliveries}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={event =>
                    handleChangeRowsPerPage(event.target.value)
                  }
                  labelRowsPerPage={'Recibos por página'}
                />
              </TableContainer>
            }
          </>
        ) : (
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Identificador da entrega</TableCell>
                  <TableCell>Telefone do cliente</TableCell>
                  <TableCell>Email do cliente</TableCell>
                  <TableCell>Status da entrega</TableCell>
                  <TableCell>Data de criação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow style={{ marginTop: 10 }}>
                  <TableCell>
                    Você ainda não possui pedidos de entrega
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <div style={{ alignSelf: 'flex-end' }}>
          <Button
            onClick={() => setModalOpen(true)}
            variant="contained"
            style={{
              backgroundColor: colors.green_dark,
              color: colors.white,
              marginTop: '15px',
              height: '40px',
            }}>
            Criar pedido de entrega
          </Button>
        </div>
      </div>
    </div>
  );
}
