import { makeStyles } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import { LockCard } from './LockCard';

import { getCondoControllers } from '../../../api/index';
import { DefaultButton } from '../../../components/Buttons/DefaultButton';
import DefaultInput from '../../../components/Inputs/DefaultInput';
import Loading from '../../../components/Loading';
import Toast from '../../../components/Toast';
import Title from '../../../components/Typography/Title';
import { colors } from '../../../styles/colors';
import { normalizeControllers } from '../../../utils/normalizeControllers';
import { sortData } from '../../../utils/sortData';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: '15px',
  },
  card: {
    margin: '45px 0px',
  },
  grid: {
    minHeight: '100px',
    maxHeight: '200px',
    overflowY: 'auto',
    padding: '10px',
  },
}));

StepOne.propTypes = {
  stepForward: PropTypes.func,
  lock: PropTypes.object,
  setLock: PropTypes.func,
};

export function StepOne({ stepForward, lock, setLock }) {
  const classes = useStyles();
  const toastRef = useRef();
  const [filtered, setFiltered] = useState([]);
  const [loading, setLoading] = useState(true);
  const [controllers, setControllers] = useState([]);

  const handleControllers = async () => {
    const res = await getCondoControllers();
    if (res.status === 200) {
      const normalizedControllers = normalizeControllers(res.data);
      const sortedControllers = sortData(
        normalizedControllers,
        'controller_name',
      );
      setControllers(sortedControllers);
      setFiltered(sortedControllers);
    } else {
      toastRef.current.handleOpen('Erro ao recuperar os armários.', 'error');
    }
    setLoading(false);
  };

  const handleControllerSelection = (id, name) => {
    setLock({
      controller: { id: id, name: name },
      block: { id: null, name: null },
      habitation: {
        id: null,
        name: null,
        ex_id: null,
        ex_uuid: null,
      },
      size: null,
    });
    stepForward();
  };

  const sanitized = str => {
    return str
      .trim()
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')
      .toLowerCase();
  };

  const filterControllersByName = e => {
    const name = e.target.value;
    const filteredControllers = controllers.filter(c =>
      sanitized(c.controller_name).includes(sanitized(name)),
    );
    setFiltered(filteredControllers);
  };

  const compactName = name => {
    return name.substr(0, 4) === 'Mini' ? name.substr(10, name.length) : name;
  };

  useEffect(() => {
    handleControllers();
  }, []);

  return (
    <>
      <Toast ref={toastRef} />

      <div className={classes.title}>
        <Title text="Selecione o armário" bold size={'1.5rem'} />
      </div>

      <DefaultInput
        onChange={filterControllersByName}
        sx={{ marginBottom: '20px' }}
      />
      {loading ? (
        <Loading />
      ) : controllers.length ? (
        <Stack direction="column" spacing={1} className={classes.grid}>
          {filtered.map(controller => {
            return controller.is_remote ? (
              <DefaultButton
                key={controller.controller_id}
                onClick={() =>
                  handleControllerSelection(
                    controller.controller_id,
                    compactName(controller.controller_name),
                  )
                }
                selected={lock.controller.id === controller.controller_id}
                sx={{ width: '100%', fontSize: '1rem' }}>
                {compactName(controller.controller_name)}
              </DefaultButton>
            ) : (
              <Tooltip
                key={controller.controller_id}
                title="Este armário não é remoto"
                placement="left">
                <div>
                  <DefaultButton
                    onClick={() =>
                      handleControllerSelection(
                        controller.controller_id,
                        compactName(controller.controller_name),
                      )
                    }
                    selected={lock.controller.id === controller.controller_id}
                    sx={{ width: '100%', fontSize: '1rem' }}
                    disabled
                    color="secondary">
                    {compactName(controller.controller_name)}
                  </DefaultButton>
                </div>
              </Tooltip>
            );
          })}
        </Stack>
      ) : (
        <Title
          text="Nenhum armário encontrado."
          bold
          paddingTop="15px"
          fontSize="1.4rem"
          color={colors.negative_40}
        />
      )}

      <div className={classes.card}>
        <LockCard lock={lock} />
      </div>
      <DefaultButton disabled sx={{ fontSize: '1.3rem' }} color="secondary">
        Confirmar dados
      </DefaultButton>
    </>
  );
}
