import { Alert, Snackbar } from '@mui/material';
import { forwardRef, useImperativeHandle, useState } from 'react';

const Toast = (_props, ref) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');
  const [type, setType] = useState('info');

  useImperativeHandle(ref, () => ({
    handleOpen: (text, type) => {
      setText(text);
      setType(type);
      setOpen(true);
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert
        onClose={handleClose}
        severity={type}
        variant="filled"
        sx={{ width: '100%' }}>
        {text}
      </Alert>
    </Snackbar>
  );
};

export default forwardRef(Toast);
