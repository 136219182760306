import PropTypes from 'prop-types';

import { colors } from '../../../styles/colors';

DeliveryStatus.propTypes = {
  status: PropTypes.string,
};

export default function DeliveryStatus(props) {
  return (
    <>
      {props.status === 'in_progress' ? (
        <div
          style={{
            backgroundColor: colors.gray_dark,
            color: colors.white,
            height: '30px',
            width: '100px',
            borderRadius: '4px',
            textAlign: 'center',
            paddingTop: '5px',
          }}>
          Em progresso
        </div>
      ) : props.status === 'delivered' ? (
        <div
          style={{
            backgroundColor: '#F2C94C',
            color: colors.white,
            height: '30px',
            width: '100px',
            borderRadius: '4px',
            textAlign: 'center',
            paddingTop: '5px',
          }}>
          Entregue
        </div>
      ) : props.status === 'completed' ? (
        <div
          style={{
            color: colors.black,
            height: '30px',
            width: '100px',
            borderRadius: '4px',
            textAlign: 'center',
            paddingTop: '5px',
          }}>
          Concluída
        </div>
      ) : props.status === 'completed' ? (
        <div
          style={{
            backgroundColor: colors.red_dark,
            height: '30px',
            width: '100px',
            borderRadius: '4px',
            textAlign: 'center',
            paddingTop: '5px',
          }}>
          Expirada
        </div>
      ) : (
        <div
          style={{
            backgroundColor: colors.red_dark,
            color: colors.white,
            height: '30px',
            width: '100px',
            borderRadius: '4px',
            textAlign: 'center',
            paddingTop: '5px',
          }}>
          Cancelada
        </div>
      )}
    </>
  );
}
