import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiPhoneNumber from 'material-ui-phone-number';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import {
  createDeliveryAdmin,
  getControllersAdmin,
  getLocksSizes,
} from '../../../api/delivery';
import { colors } from '../../../styles/colors';

ModalDeliveryAdmin.propTypes = {
  close: PropTypes.func,
  open: PropTypes.bool,
};

export default function ModalDeliveryAdmin(props) {
  const [clientCellphone, setClientCellphone] = useState('');
  const [controllers, setControllers] = useState(null);
  const [orderId, setOrderId] = useState('');
  const [sizeList, setSizeList] = useState(null);
  const [sizeSelected, setSizeSelected] = useState('');
  const [selectedControllerId, setSelectedControllerId] = useState('');
  const [passcodeType, setPasscodeType] = useState('receiveOnly');
  const [displayError, setDisplayError] = useState(false);

  const closeModal = () => {
    setClientCellphone('');
    setControllers(null);
    setOrderId('');
    setSizeList(null);
    setSizeSelected('');
    setSelectedControllerId('');
    setPasscodeType('receiveOnly');
    setDisplayError(false);
    props.close();
  };

  const handleChangeClientCellphone = clientCellphone => {
    setClientCellphone(
      clientCellphone.replace('(', '').replace(')', '').replace(/\s+/g, ''),
    );
  };

  const getSizeList = async controllerId => {
    const response = await getLocksSizes(controllerId);
    const sizeList = response.data.result;
    setSizeList(sizeList);
  };

  const handleChangeSelectedControllerId = controllerId => {
    setSelectedControllerId(controllerId.target.value);
    getSizeList(controllerId.target.value);
  };

  const handleChangeOrderId = orderId => {
    setOrderId(orderId.target.value);
  };

  const handleChangeSize = size => {
    setSizeSelected(size.target.value);
  };

  const handleChangePasscodeType = passcodeType => {
    setPasscodeType(passcodeType.target.value);
  };

  const createDelivery = async () => {
    const response = await createDeliveryAdmin(
      orderId,
      clientCellphone,
      selectedControllerId,
      sizeSelected,
      passcodeType,
    );
    if (response.status === 201) {
      setDisplayError(false);
      closeModal();
    } else {
      setDisplayError(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await getControllersAdmin();
      const controllers = response.data.result;
      setControllers(controllers);
    };
    if (props.open === true) {
      fetchData();
    }
  }, [props.open]);

  return (
    <Modal
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={props.open}
      onClose={closeModal}
      aria-labelledby="delivery"
      aria-describedby="delivery-modal">
      <div
        style={{
          backgroundColor: 'white',
          minWidth: '600px',
          minHeight: '700px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'space-around',
          padding: '10px',
        }}>
        <Button
          onClick={() => {
            closeModal();
          }}
          style={{ alignSelf: 'flex-end', margin: 0, padding: 0 }}>
          {<CloseIcon />}
        </Button>
        {displayError ? (
          <Typography style={{ color: colors.red }}>
            Erro ao Criar Pedido!!!
          </Typography>
        ) : (
          <></>
        )}
        <TextField
          style={{ width: 300 }}
          label="Identificador do pedido"
          variant="outlined"
          value={orderId}
          onChange={handleChangeOrderId}
        />

        <MuiPhoneNumber
          style={{ width: 300 }}
          variant="outlined"
          label="Celular do cliente"
          defaultCountry={'br'}
          value={clientCellphone}
          onChange={handleChangeClientCellphone}
        />

        <FormControl fullWidth style={{ width: 300, alignSelf: 'center' }}>
          <InputLabel>Selecione o armário</InputLabel>
          <Select
            labelId="controller-label"
            id="controller-select"
            value={selectedControllerId}
            label="Selecione o armário"
            onChange={handleChangeSelectedControllerId}>
            {controllers !== null ? (
              controllers.map(controller => {
                return (
                  <MenuItem key={controller.id} value={controller.id}>
                    {controller.name}
                  </MenuItem>
                );
              })
            ) : (
              <FormHelperText>Nenhum armário encontrado.</FormHelperText>
            )}
          </Select>
        </FormControl>

        <FormControl fullWidth style={{ width: 300, alignSelf: 'center' }}>
          <InputLabel>Selecione a porta</InputLabel>
          <Select
            labelId="lock-size-label"
            id="lock-size-select"
            value={sizeSelected}
            label="Selecione a porta"
            onChange={handleChangeSize}>
            {sizeList ? (
              Object.entries(sizeList).map(key => {
                return (
                  <MenuItem
                    style={{
                      whiteSpace: 'unset',
                      wordBreak: 'break-all',
                    }}
                    key={key[0]}
                    value={key[0]}>
                    <ul className="stylingList">
                      <li>Tamanho: {key[0]}</li>
                      <li>Quantidade: {key[1].amount}</li>
                      <li>Dimensão: {key[1].dimension}</li>
                    </ul>
                  </MenuItem>
                );
              })
            ) : (
              <FormHelperText>
                Nenhuma porta disponível no momento.
              </FormHelperText>
            )}
          </Select>
        </FormControl>

        <FormControl component="fieldset">
          <FormLabel component="legend">Tipo de senha</FormLabel>
          <RadioGroup
            aria-label="passcode-type"
            defaultValue="receiveOnly"
            name="radio-buttons-group"
            value={passcodeType}
            onChange={handleChangePasscodeType}>
            <FormControlLabel
              value="receiveOnly"
              control={<Radio />}
              label="Somente retirada"
            />
            <FormControlLabel
              value="pair"
              control={<Radio />}
              label="Entrega e retirada"
            />
          </RadioGroup>
        </FormControl>

        <Button
          onClick={() => createDelivery()}
          variant="contained"
          style={{
            backgroundColor: colors.blue,
            color: colors.background,
          }}>
          Criar Pedido
        </Button>
      </div>
    </Modal>
  );
}
