import { useEffect, useState } from 'react';

import DashboardCard from './DashboardCard';

import { getTotalDeliveries } from '../../../api/dashboard';

export function TotalDeliveries() {
  const [cardData, setCardData] = useState('');

  useEffect(() => {
    getTotalDeliveries().then(response => {
      setCardData(response.result);
    });
  }, []);

  return (
    <DashboardCard
      data={`${cardData}`}
      title="Total de entregas"
      subtitle="desde o início"
    />
  );
}
