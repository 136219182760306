import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { sendSmsDelivery } from '../../../api/delivery';
import { colors } from '../../../styles/colors';

SmsAlert.propTypes = {
  disabled: PropTypes.bool,
  deliveryId: PropTypes.number,
};

export default function SmsAlert(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sendSms = async deliveryId => {
    await sendSmsDelivery(deliveryId);
    handleClose();
  };

  return (
    <div>
      <Button
        style={
          props.disabled === false
            ? {
                backgroundColor: colors.blue,
                color: 'white',
                marginRight: '20px',
                height: '45px',
                width: '100px',
                fontSize: '10px',
                fontWeight: 'bold',
                padding: '15px',
              }
            : {
                backgroundColor: colors.gray_dark,
                color: 'white',
                marginRight: '20px',
                height: '45px',
                width: '100px',
                fontSize: '10px',
                fontWeight: 'bold',
                padding: '15px',
              }
        }
        variant="outlined"
        onClick={handleClickOpen}
        disabled={props.disabled}>
        Enviar SMS ao cliente
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Deseja enviar uma SMS ao cliente?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Envia uma SMS ao cliente para informar que a entrega foi feita, a
            senha de retirada e o número da porta onde a entrega foi feita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: colors.gray_dark }} onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            style={{ fontWeight: 'bold' }}
            onClick={() => {
              sendSms(props.deliveryId);
            }}
            autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
