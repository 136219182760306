import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import { colors } from '../../styles/colors';

AccordionItem.propTypes = {
  defaultExpanded: PropTypes.bool,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default function AccordionItem({ defaultExpanded, title, text }) {
  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary
        expandIcon={<ArrowDownwardIcon />}
        aria-controls="panel1-content"
        id="panel1-header">
        <Typography style={{ fontWeight: 'bold', color: colors.dark_purple }}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{text}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}
