import {
  Divider,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import LogoutIcon from '@mui/icons-material/Logout';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

import MenuButton from './MenuButton';
import { pathsArray } from './paths';

import Logo from '../../images/logo.png';
import { colors } from '../../styles/colors';
import MenuText from '../Typography/MenuText/index';

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '28px',
    background: colors.white,
    margin: '16% 0 16% 24%',
    height: 'calc(100vh - 50px)',
    overflowY: 'auto',
  },
  toolbarMobile: {
    display: 'flex',
    flexDirection: 'column',
    background: colors.white,
    height: '100%',
    width: '100%',
    overflowY: 'auto',
  },
  logo: {
    padding: '30px 42.5px 50px 42.5px',
    width: '100%',
  },
  divider: {
    paddingTop: '20px',
    paddingLeft: '26px',
    paddingRight: '26px',
  },
  logout: {
    color: colors.light_gray,
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  logoutText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoutIcon: {
    marginRight: '8px',
  },
}));

Menu.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  condoCompany: PropTypes.object,
  logout: PropTypes.func,
  mobile: PropTypes.bool,
};

export function Menu({ title, type, condoCompany, logout, mobile }) {
  const classes = useStyles();
  const paths = pathsArray(type);

  return (
    <div className={mobile ? classes.toolbarMobile : classes.toolbar}>
      <a href="/" className={classes.logo}>
        <img
          style={{ width: '100%' }}
          src={
            condoCompany && condoCompany?.logo_url
              ? condoCompany?.logo_url
              : Logo
          }
          alt=""
        />
      </a>
      <List>
        {paths.map(path => (
          <MenuButton
            key={path.title}
            text={path.title}
            icon={path.icon}
            route={path.route}
            selected={title.toLowerCase() === path.title.toLowerCase()}
          />
        ))}

        <div className={classes.divider}>
          <Divider sx={{ borderBottomWidth: '10px' }} />
        </div>

        <ListItem
          onClick={logout}
          button
          key="Logout"
          className={classes.logout}>
          <ListItemText disableTypography className={classes.logoutText}>
            <>
              <LogoutIcon className={classes.logoutIcon} />
              <MenuText text="LOGOUT" />
            </>
          </ListItemText>
        </ListItem>
      </List>
    </div>
  );
}

MenuRoutes.propTypes = {
  type: PropTypes.string,
};

export function MenuRoutes(props) {
  const paths = pathsArray(props.type);

  return (
    <>
      {paths ? (
        paths.map(path => (
          <Route
            exact
            path={path.route}
            render={path.render(props)}
            key={path.route}
          />
        ))
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
}
