import { Button } from '@material-ui/core';
import { styled } from '@mui/material/styles';

import { colors } from '../../styles/colors';

export const DefaultButton = styled(Button)(({ variant, color }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  lineHeight: '85%',
  padding: '0.8em 2em',
  borderRadius: '0.5em',
  backgroundColor:
    variant === 'outlined'
      ? 'transparent'
      : color === 'secondary'
        ? '#E6E7E8'
        : colors.light_purple,
  color: color === 'secondary' ? '#797C81' : colors.white,
  border: variant === 'outlined' ? '1px solid #797C81' : 'none',
  fontFamily: ['Montserrat'].join(','),
  '&:hover': {
    backgroundColor:
      variant === 'outlined'
        ? '#E6E7E8'
        : color === 'secondary'
          ? '#cfcfd0'
          : colors.light_purple_shade,
    border: variant === 'outlined' ? '1px solid #797C81' : 'none',
  },
  '&:active': {
    backgroundColor:
      variant === 'outlined'
        ? '#E6E7E8'
        : color === 'secondary'
          ? '#cfcfd0'
          : colors.light_purple_shade,
    border: variant === 'outlined' ? '1px solid #797C81' : 'none',
  },
  '&:focus': {
    backgroundColor:
      variant === 'outlined'
        ? '#E6E7E8'
        : color === 'secondary'
          ? '#cfcfd0'
          : colors.light_purple_shade,
    border: variant === 'outlined' ? '1px solid #797C81' : 'none',
  },
}));
