import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 3 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const categories = ['App', 'Correios', 'Lockin'];

function getStyles(category, categoryLabel, theme) {
  return {
    fontWeight:
      categoryLabel.indexOf(category) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

MultipleSelect.propTypes = {
  selectedCategories: PropTypes.array,
  setSelectedCategories: PropTypes.func,
  handleGetLockerLogs: PropTypes.func,
  setPage: PropTypes.func,
  setRowsPerPage: PropTypes.func,
};

export default function MultipleSelect({
  selectedCategories,
  setSelectedCategories,
  handleGetLockerLogs,
  setPage,
  setRowsPerPage,
}) {
  const theme = useTheme();

  const handleChange = async categories => {
    setSelectedCategories(
      typeof categories === 'string' ? categories.split(',') : categories,
    );
    const normalizedCategories = categories.map(category =>
      category.toLowerCase(),
    );
    setPage(0);
    setRowsPerPage(10);
    await handleGetLockerLogs(0, 10, normalizedCategories);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-chip-label">Categorias</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={selectedCategories}
          onChange={event => handleChange(event.target.value)}
          input={<OutlinedInput id="select-multiple-chip" label="Categorias" />}
          renderValue={selected => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map(value => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}>
          {categories.map(category => (
            <MenuItem
              key={category}
              value={category}
              style={getStyles(category, selectedCategories, theme)}>
              {category}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
