import axios from 'axios';

const baseUrl = process.env.REACT_APP_LOOPBOX_API_URL;

export const login = async (email, password) => {
  return axios
    .post(baseUrl + '/login', {
      user: { email: email, password: password },
    })
    .then(async response => {
      return axios
        .get(baseUrl + '/v1/users/account/type', {
          headers: { Authorization: response.headers.authorization },
        })
        .then(responseCondoCompany => {
          const responseObj = {
            token: response.headers.authorization,
            type: responseCondoCompany.data.type,
            condoCompanyData: responseCondoCompany.data.response,
          };
          return responseObj;
        })
        .catch(() => {
          return 404;
        });
    })
    .catch(() => {
      return 404;
    });
};

export const logout = async () => {
  const token = sessionStorage.getItem('token');

  return axios
    .delete(baseUrl + '/logout', {
      headers: { Authorization: token.replace(/['"]+/g, '') },
    })
    .then(() => {
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('type');
      sessionStorage.removeItem('condoCompany');
      return 'OK';
    })
    .catch(() => {
      return 'NOT CONDO';
    });
};

export const getBlocks = async () => {
  const token = sessionStorage.getItem('token');

  return axios
    .get(baseUrl + '/v1/condo/blocks', {
      headers: { Authorization: token.replace(/['"]+/g, '') },
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getControllerBlocks = async controller_id => {
  const token = sessionStorage.getItem('token');

  return axios
    .get(baseUrl + '/v1/condo/controller/' + controller_id + '/blocks', {
      headers: { Authorization: token.replace(/['"]+/g, '') },
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getLocksPerSize = async controller_id => {
  const token = sessionStorage.getItem('token');

  return axios
    .get(baseUrl + '/v1/locks/' + controller_id + '/size', {
      headers: { Authorization: token.replace(/['"]+/g, '') },
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getBlockHabitations = async (block_id, limit, offset) => {
  const token = sessionStorage.getItem('token');

  return axios
    .get(baseUrl + '/v2/condo/block/' + block_id + '/habitations', {
      params: {
        limit: limit,
        offset: offset,
      },
      headers: { Authorization: token.replace(/['"]+/g, '') },
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getCondoControllers = async () => {
  const token = sessionStorage.getItem('token');

  return axios
    .get(baseUrl + '/v1/condo/controllers/show', {
      headers: { Authorization: token.replace(/['"]+/g, '') },
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getControllerDetails = async controllerId => {
  const token = sessionStorage.getItem('token');

  return axios
    .get(baseUrl + '/v2/locker/' + controllerId + '/gateway_info', {
      headers: { Authorization: token.replace(/['"]+/g, '') },
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const openLock = async lock_id => {
  const token = sessionStorage.getItem('token');

  return axios
    .post(
      baseUrl + '/v2/locker/online/open',
      { lock_id: lock_id },
      { headers: { Authorization: token.replace(/['"]+/g, '') } },
    )
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const remoteDelivery = async (
  lock_size,
  controller_id,
  habitation_id,
  habitation_external_id,
  habitation_external_uuid,
) => {
  const token = sessionStorage.getItem('token');

  return axios
    .post(
      baseUrl + '/v2/condo/online/delivery',
      {
        lock_size,
        controller_id,
        habitation_id,
        habitation_external_id,
        habitation_external_uuid,
        from_lockin: true,
      },
      { headers: { Authorization: token.replace(/['"]+/g, '') } },
    )
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getCondoLocks = async controller_id => {
  const token = sessionStorage.getItem('token');

  return axios
    .get(baseUrl + '/v1/condo/controller/' + controller_id + '/locks', {
      headers: { Authorization: token.replace(/['"]+/g, '') },
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getCondoLocksPasscodes = async (lock_id, limit, offset) => {
  const token = sessionStorage.getItem('token');

  return axios
    .get(baseUrl + '/v1/condo/lock/' + lock_id + '/passcodes', {
      params: {
        limit: limit,
        offset: offset,
      },
      headers: { Authorization: token.replace(/['"]+/g, '') },
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const confirmReverseLogistic = async passcode_id => {
  const token = sessionStorage.getItem('token');

  return axios
    .post(
      baseUrl + '/v1/condo/reverseLogistic/' + passcode_id,
      { passcode_id: passcode_id },
      {
        headers: { Authorization: token.replace(/['"]+/g, '') },
      },
    )
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getCondoLogs = async (limit, offset) => {
  const token = sessionStorage.getItem('token');

  return axios
    .get(baseUrl + '/v1/logs/show', {
      params: {
        limit: limit,
        offset: offset,
      },
      headers: { Authorization: token.replace(/['"]+/g, '') },
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getCondoReceipts = async () => {
  const token = sessionStorage.getItem('token');

  return axios
    .get(baseUrl + '/v1/receipt/show', {
      headers: { Authorization: token.replace(/['"]+/g, '') },
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const createHabitation = async (block_id, habitation) => {
  const token = sessionStorage.getItem('token');

  return axios
    .post(
      baseUrl + '/v1/habitations/create/',
      {
        block_id: block_id,
        habitation_name: habitation.name,
        habitation_cellphone: habitation.cellphone,
        habitation_email: habitation.email,
        external_id: habitation.habitation_external_id,
        external_uuid: habitation.habitation_external_uuid,
      },
      {
        headers: { Authorization: token.replace(/['"]+/g, '') },
      },
    )
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const updateHabitation = async habitation => {
  const token = sessionStorage.getItem('token');

  return axios
    .post(
      baseUrl + '/v1/habitations/update/' + habitation.id,
      {
        habitation_name: habitation.name,
        habitation_cellphone: habitation.cellphone,
        habitation_email: habitation.email,
        external_id: habitation.habitation_external_id,
        external_uuid: habitation.habitation_external_uuid,
      },
      {
        headers: { Authorization: token.replace(/['"]+/g, '') },
      },
    )
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const deleteHabitation = async habitation_id => {
  const token = sessionStorage.getItem('token');

  return axios
    .delete(baseUrl + '/v1/habitations/delete/' + habitation_id, {
      headers: { Authorization: token.replace(/['"]+/g, '') },
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const updateStatus = async (lock_id, status) => {
  const token = sessionStorage.getItem('token');

  return axios
    .patch(
      baseUrl + '/v2/locker/lock/' + lock_id + '/edit/status',
      {
        status: status,
      },
      {
        headers: { Authorization: token.replace(/['"]+/g, '') },
        timeout: 10000,
      },
    )
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const updatePasscodeStatus = async (lock_id, passcode_id, activate) => {
  const token = sessionStorage.getItem('token');

  return axios
    .patch(
      baseUrl + '/v2/condo/passcode/edit/status',
      {
        lock_id: lock_id,
        passcode_id: passcode_id,
        activate: activate,
      },
      {
        headers: { Authorization: token.replace(/['"]+/g, '') },
        timeout: 10000,
      },
    )
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getHabitationPasscodes = async (habitation_id, limit, offset) => {
  const token = sessionStorage.getItem('token');

  return axios
    .get(baseUrl + '/v2/condo/habitation/' + habitation_id + '/passcodes', {
      params: {
        limit: limit,
        offset: offset,
      },
      headers: {
        Authorization: token.replace(/['"]+/g, ''),
      },
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getLockerLogs = async (
  controller_id,
  limit,
  offset,
  categories,
  searchTerm,
  orderBy,
  orderDirection,
) => {
  const token = sessionStorage.getItem('token');

  return axios
    .get(baseUrl + '/v2/locker/' + controller_id + '/logs', {
      params: {
        limit,
        offset,
        category: categories,
        search_term: searchTerm,
        order_by: orderBy,
        order_direction: orderDirection,
      },
      headers: {
        Authorization: token.replace(/['"]+/g, ''),
      },
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const getBlockReceipts = async (block_id, limit, offset) => {
  const token = sessionStorage.getItem('token');

  return axios
    .get(baseUrl + '/v2/condo/block/' + block_id + '/receipts', {
      params: {
        limit: limit,
        offset: offset,
      },
      headers: {
        Authorization: token.replace(/['"]+/g, ''),
      },
    })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const resetAdminPasscode = async (condo_id, controller_id) => {
  const token = sessionStorage.getItem('token');

  return axios
    .patch(
      baseUrl + '/v2/condo/passcodes/admin/reset',
      {
        condo_id: condo_id,
        controller_id: controller_id,
      },
      {
        headers: {
          Authorization: token.replace(/['"]+/g, ''),
        },
      },
    )
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const registerLog = async (event_date, event_name) => {
  const token = sessionStorage.getItem('token');

  return axios
    .post(
      baseUrl + '/v1/logs/register',
      {
        event_date,
        event_name,
      },
      {
        headers: {
          Authorization: token.replace(/['"]+/g, ''),
        },
      },
    )
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};

export const changeCondoStatus = async (condoId, status) => {
  const token = sessionStorage.getItem('token');

  return axios
    .patch(
      baseUrl + '/v2/condo/status',
      {
        condo_id: condoId,
        status: status,
      },
      {
        headers: {
          Authorization: token.replace(/['"]+/g, ''),
        },
      },
    )
    .then(response => {
      return response;
    })
    .catch(error => {
      return error.response;
    });
};
