import PropTypes from 'prop-types';

Header.propTypes = {
  text: PropTypes.string,
};

export function Header({ text, ...props }) {
  return (
    <span
      style={{
        fontFamily: 'Montserrat',
        fontWeight: '300',
        fontSize: '1.5rem',
        lineHeight: '100%',
        letterSpacing: '0.1em',
        textOverflow: 'ellipsis',
        /* Required for text-overflow to do anything */
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        ...props,
      }}>
      {text}
    </span>
  );
}
