import BarChartIcon from '@material-ui/icons/BarChart';
import HistoryIcon from '@material-ui/icons/History';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import ReceiptIcon from '@material-ui/icons/Receipt';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import BusinessIcon from '@mui/icons-material/Business';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';

import AdministeredCompanies from '../../pages/AdministeredCompanies';
import AdministeredCondos from '../../pages/AdministeredCondos';
import Companies from '../../pages/Companies';
import Condos from '../../pages/Condos';
import Dashboard from '../../pages/Dashboard';
import Delivery from '../../pages/Delivery';
import DeliveryAdmin from '../../pages/DeliveryAdmin';
import Habitations from '../../pages/Habitations';
import Lockers from '../../pages/Lockers';
import Logs from '../../pages/Logs';
import Receipts from '../../pages/Receipts';
import RemoteDelivery from '../../pages/RemoteDelivery';

export const pathsArray = type =>
  type === 'condo'
    ? [
        {
          title: 'VISÃO GERAL',
          route: '/dashboard',
          icon: <BarChartIcon />,
          render: function (props) {
            return () => <Dashboard {...props} handler={props.handler} />;
          },
        },
        {
          title: 'MORADIAS',
          route: '/moradias',
          icon: <HomeWorkIcon />,
          render: function (props) {
            return () => <Habitations {...props} handler={props.handler} />;
          },
        },
        {
          title: 'ARMÁRIOS',
          route: '/armarios',
          icon: <VpnKeyIcon />,
          render: function (props) {
            return () => <Lockers {...props} handler={props.handler} />;
          },
        },
        {
          title: 'CRIAR ENTREGA',
          route: '/remote_delivery',
          icon: <BarChartIcon />,
          render: function (props) {
            return () => <RemoteDelivery {...props} handler={props.handler} />;
          },
        },
        {
          title: 'RECIBOS',
          route: '/recibos',
          icon: <ReceiptIcon />,
          render: function (props) {
            return () => <Receipts {...props} handler={props.handler} />;
          },
        },
        {
          title: 'HISTÓRICO DE USO',
          route: '/historico',
          icon: <HistoryIcon />,
          render: function (props) {
            return () => <Logs {...props} handler={props.handler} />;
          },
        },
      ]
    : type === 'company'
      ? [
          {
            title: 'ENTREGAS',
            route: '/entregas',
            icon: <DeliveryDiningIcon />,
            render: function (props) {
              return () => <Delivery {...props} handler={props.handler} />;
            },
          },
        ]
      : type === 'administrator-company'
        ? [
            {
              title: 'EMPRESAS',
              route: '/empresas',
              icon: <HomeWorkIcon />,
              render: function (props) {
                return () => (
                  <AdministeredCompanies {...props} handler={props.handler} />
                );
              },
            },
            {
              title: 'ENTREGAS',
              route: '/entregas',
              icon: <DeliveryDiningIcon />,
              render: function (props) {
                return () => (
                  <DeliveryAdmin {...props} handler={props.handler} />
                );
              },
            },
          ]
        : type === 'administrator-condo'
          ? [
              {
                title: 'CONDOS',
                route: '/condos',
                icon: <HomeWorkIcon />,
                render: function (props) {
                  return () => (
                    <AdministeredCondos {...props} handler={props.handler} />
                  );
                },
              },
            ]
          : type === 'lockin'
            ? [
                {
                  title: 'CONDOS',
                  route: '/condos',
                  icon: <HomeWorkIcon />,
                  render: function (props) {
                    return () => <Condos {...props} handler={props.handler} />;
                  },
                },
                {
                  title: 'EMPRESAS',
                  route: '/empresas',
                  icon: <BusinessIcon />,
                  render: function (props) {
                    return () => (
                      <Companies {...props} handler={props.handler} />
                    );
                  },
                },
              ]
            : [];
