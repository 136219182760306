import PropTypes from 'prop-types';

MenuText.propTypes = {
  text: PropTypes.string,
};

export default function MenuText({ text }) {
  return (
    <span
      style={{
        fontFamily: 'Livvic',
        fontWeight: 500,
        fontSize: '1rem',
      }}>
      {text}
    </span>
  );
}
