/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, makeStyles } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import { GoBackButton } from './GoBackButton';
import { LockCard } from './LockCard';

import { getBlockHabitations } from '../../../api/index';
import { DefaultButton } from '../../../components/Buttons/DefaultButton';
import DefaultInput from '../../../components/Inputs/DefaultInput';
import Loading from '../../../components/Loading';
import Toast from '../../../components/Toast';
import Title from '../../../components/Typography/Title';
import { colors } from '../../../styles/colors';
import { sortData } from '../../../utils/sortData';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: '15px',
  },
  card: {
    margin: '45px 0px',
  },
  grid: {
    minHeight: '100px',
    maxHeight: '300px',
    overflowY: 'auto',
    minWidth: '200px',
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
  },
}));

StepThree.propTypes = {
  stepForward: PropTypes.func,
  stepBackward: PropTypes.func,
  lock: PropTypes.object,
  setLock: PropTypes.func,
};

export function StepThree({ stepForward, stepBackward, lock, setLock }) {
  const classes = useStyles();
  const toastRef = useRef();
  const [filtered, setFiltered] = useState([]);
  const [habitations, setHabitations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const habitationsPaginationLimit = 12;

  const handleHabitations = async () => {
    setLoading(true);
    const res = await getBlockHabitations(
      lock.block.id,
      habitationsPaginationLimit,
      page,
    );
    if (res.status === 200) {
      const sortedHabitations = sortData(res.data.result.habitations, 'name');
      setHabitations(sortedHabitations);
      setFiltered(sortedHabitations);
      setLastPage(
        Math.ceil(res.data.result.total / habitationsPaginationLimit),
      );
    } else {
      toastRef.current.handleOpen(
        'Erro ao recuperar os apartamentos.',
        'error',
      );
    }
    setLoading(false);
  };

  const handleHabitationSelection = (id, name, ex_id, ex_uuid) => {
    setLock({
      ...lock,
      habitation: {
        id,
        name,
        ex_id,
        ex_uuid,
      },
      size: null,
    });
    stepForward();
  };

  const sanitized = str => {
    return str
      .trim()
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')
      .toLowerCase();
  };

  const filterHabitationsByName = e => {
    const name = e.target.value;
    const res = habitations.filter(c =>
      sanitized(c.name).includes(sanitized(name)),
    );
    setFiltered(res);
  };

  useEffect(() => {
    handleHabitations();
  }, [page]);

  useEffect(() => {
    handleHabitations();
  }, []);

  return (
    <>
      {loading && <Loading />}

      <Toast ref={toastRef} />

      <div className={classes.header} onClick={stepBackward}>
        <GoBackButton />
      </div>

      <div className={classes.title}>
        <Title text="Selecione o apartamento" bold />
      </div>

      <DefaultInput
        onChange={filterHabitationsByName}
        sx={{ marginBottom: '20px' }}
      />

      {loading ? (
        <Loading />
      ) : habitations.length ? (
        <div style={{ display: 'flex' }}>
          {page > 1 ? (
            <Button onClick={() => setPage(page - 1)}>
              <KeyboardArrowLeftIcon
                style={{ fontSize: '60px', height: '100px' }}
              />
            </Button>
          ) : (
            <></>
          )}
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
            className={classes.grid}>
            {filtered.map(habitation => (
              <Grid item key={habitation.id} lg={2} md={3} sm={4} xs={6}>
                <DefaultButton
                  onClick={() =>
                    handleHabitationSelection(
                      habitation.id,
                      habitation.name,
                      habitation.external_id,
                      habitation.external_uuid,
                    )
                  }
                  selected={lock.habitation.id === habitation.id}
                  sx={{
                    fontSize: '1rem',
                    width: '9rem',
                    marginX: '4px',
                    height: '4rem',
                  }}>
                  {habitation.name}
                </DefaultButton>
              </Grid>
            ))}
          </Grid>
          {page >= lastPage ? (
            <></>
          ) : (
            <Button onClick={() => setPage(page + 1)}>
              <KeyboardArrowRightIcon
                style={{ fontSize: '60px', height: '100px' }}
              />
            </Button>
          )}
        </div>
      ) : (
        <Title
          text="Nenhum apartamento encontrado."
          bold
          paddingTop="15px"
          fontSize="1.4rem"
          color={colors.negative_40}
        />
      )}

      <div className={classes.card}>
        <LockCard lock={lock} />
      </div>
      <DefaultButton disabled sx={{ fontSize: '1.3rem' }} color="secondary">
        Confirmar dados
      </DefaultButton>
    </>
  );
}
