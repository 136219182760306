import { useEffect, useState } from 'react';

import DashboardCard from './DashboardCard';

import { getTotalOpenings } from '../../../api/dashboard';

export function TotalOpenings() {
  const [cardData, setCardData] = useState('');

  useEffect(() => {
    getTotalOpenings().then(response => {
      setCardData(response.result);
    });
  }, []);

  return (
    <div>
      <DashboardCard
        data={`${cardData}`}
        title="Total de aberturas"
        subtitle="desde o início"
      />
    </div>
  );
}
