import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyIcon from '@mui/icons-material/Key';
import SignalWifi1BarIcon from '@mui/icons-material/SignalWifi1Bar';
import SignalWifi2BarIcon from '@mui/icons-material/SignalWifi2Bar';
import SignalWifi3BarIcon from '@mui/icons-material/SignalWifi3Bar';
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar';
import { Alert, AlertTitle } from '@mui/material';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useRef, useState } from 'react';

import ModalAdminPasscodes from './components/ModalAdminPasscodes';
import ModalPasscodes from './components/ModalPasscodes';

import {
  confirmReverseLogistic,
  getCondoControllers,
  getCondoLocks,
  getCondoLocksPasscodes,
  getControllerDetails,
  openLock,
  resetAdminPasscode,
  updatePasscodeStatus,
  updateStatus,
} from '../../api/index';
import Loading from '../../components/Loading';
import Toast from '../../components/Toast';
import { colors } from '../../styles/colors';
import { formatDateTime } from '../../utils/formatDateTime';
import { normalizeControllers } from '../../utils/normalizeControllers';
import { sortData } from '../../utils/sortData';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

Lockers.propTypes = {
  handler: PropTypes.func,
};

export default function Lockers(props) {
  const toastRef = useRef();
  const [alertPasscodeStatusError, setAlertPasscodeStatusError] =
    useState(false);
  const [controller, setController] = useState('');
  const [controllers, setControllers] = useState('');
  const [details, setDetails] = useState('');
  const [loading, setLoading] = useState(false);
  const [lock, setLock] = useState({});
  const [lockIdToBeOpened, setLockIdToBeOpened] = useState(0);
  const [locks, setLocks] = useState('');
  const [lockSelected, setLockSelected] = useState('');
  const [lockStatus, setLockStatus] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [openModalAdmin, setOpenModalAdmin] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openRemoteOpeningAlert, setOpenRemoteOpeningAlert] = useState(false);
  const [openRemoteOpeningDialog, setOpenRemoteOpeningDialog] = useState(false);
  const [openStatusChangeDialog, setOpenStatusChangeDialog] = useState(false);
  const [openPasscodeStatusChangeDialog, setOpenPasscodeStatusChangeDialog] =
    useState(false);
  const [page, setPage] = useState(0);
  const [pageModal, setPageModal] = useState(0);
  const [passcode, setPasscode] = useState('');
  const [passcodeStatus, setPasscodeStatus] = useState('');
  const [passcodes, setPasscodes] = useState({});
  const [passcodeLockId, setPasscodeLockId] = useState(0);
  const [passcodeSelectedId, setPasscodeSelectedId] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsPerPageModal, setRowsPerPageModal] = useState(10);
  const [remoteOpeningResponse, setRemoteOpeningResponse] = useState(null);

  const getLocks = async controller => {
    const response = await getCondoLocks(controller.controller_id);
    if (response.status === 200) {
      setLocks(response.data.locks);
    } else {
      toastRef.current.handleOpen('Erro ao recuperar as portas.', 'error');
    }
  };

  const getDetails = async controller => {
    const response = await getControllerDetails(controller.controller_id);
    if (response.status === 200) {
      setDetails(response.data.result);
    } else {
      setDetails('');
    }
  };

  const handleChangeController = controllerInput => {
    setController(controllerInput);
    getLocks(controllerInput);
    getDetails(controllerInput);
  };

  const handleOpenLock = async () => {
    const response = await openLock(lockIdToBeOpened);
    setOpenRemoteOpeningDialog(false);
    setRemoteOpeningResponse(response.data);
    setOpenRemoteOpeningAlert(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = rowsPerPageInput => {
    setRowsPerPage(parseInt(rowsPerPageInput, 10));
    setPage(0);
  };

  const handleGetCondoLocksPasscodes = async (
    lockId,
    newPage = 0,
    newRowsPerPage = 10,
  ) => {
    const offset = newPage + 1;
    const limit = newRowsPerPage;
    const response = await getCondoLocksPasscodes(lockId, limit, offset);
    if (response.status === 200) {
      setPasscodes(response.data.response);
    } else {
      toastRef.current.handleOpen('Erro ao recuperar as senhas.', 'error');
    }
  };

  const handleChangePageModal = (event, newPage) => {
    setPageModal(newPage);
    handleGetCondoLocksPasscodes(lock.id, newPage, rowsPerPageModal);
  };

  const handleChangeRowsPerPageModal = rowsPerPageModalInput => {
    const newRowsPerPageModal = parseInt(rowsPerPageModalInput, 10);
    setRowsPerPageModal(newRowsPerPageModal);
    setPageModal(0);
    handleGetCondoLocksPasscodes(lock.id, 0, newRowsPerPageModal);
  };

  const openModal = async lockInput => {
    setLock(lockInput);
    await handleGetCondoLocksPasscodes(lockInput.id);
    setModalOpen(true);
  };

  const closeModal = () => {
    setPasscodes({});
    setLock({});
    setModalOpen(false);
    setPageModal(0);
    setRowsPerPageModal(10);
  };

  const openAdminModal = () => {
    setOpenModalAdmin(true);
  };

  const handleCloseModalAdmin = () => {
    setOpenModalAdmin(false);
  };

  const handleClickOpen = passcodeInput => {
    setPasscode(passcodeInput);
    setOpenDialog(true);
  };

  const handleClose = async () => {
    const response = await getCondoLocks(controller.controller_id);
    setLocks(response.data.locks);
    setPasscode('');
    setOpenDialog(false);
  };

  const handleLoading = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 15000);
  };

  const handleOpenStatusChangeDialog = () => {
    setOpenStatusChangeDialog(true);
  };

  const handleCloseStatusChangeDialog = () => {
    setOpenStatusChangeDialog(false);
  };

  const acceptStatusChangeDialog = async () => {
    handleCloseStatusChangeDialog();
    handleLoading();
    const response = await updateStatus(lockSelected.id, lockStatus);
    if (response.data?.status === 200) {
      await getLocks(controller);
      toastRef.current.handleOpen(
        'Alteração do status da porta feita com sucesso!',
        'success',
      );
    } else {
      toastRef.current.handleOpen(
        'Erro ao alterar o status da porta.',
        'error',
      );
    }
    setLoading(false);
  };

  const handleStatusChange = async (lock, status) => {
    setLockSelected(lock);
    setLockStatus(status);
    handleOpenStatusChangeDialog();
  };

  const handleOpenPasscodeStatusChangeDialog = () => {
    setOpenPasscodeStatusChangeDialog(true);
  };

  const closePasscodeStatusChangeDialog = () => {
    setOpenPasscodeStatusChangeDialog(false);
  };

  const acceptPasscodeStatusChangeDialog = async () => {
    closePasscodeStatusChangeDialog();
    handleLoading();
    const response = await updatePasscodeStatus(
      passcodeLockId,
      passcodeSelectedId,
      passcodeStatus,
    );
    if (response.data.status === 200) {
      await getLocks(controller);
      const responsePasscodes = await getCondoLocksPasscodes(
        passcodeLockId,
        rowsPerPageModal,
        pageModal + 1,
      );
      setPasscodes(responsePasscodes.data.response);
    } else {
      setAlertPasscodeStatusError(true);
      closeModal();
    }
    setLoading(false);
  };

  const handlePasscodeStatusChange = async (lockId, passcodeId, status) => {
    setPasscodeLockId(lockId);
    setPasscodeSelectedId(passcodeId);
    setPasscodeStatus(status);
    handleOpenPasscodeStatusChangeDialog();
  };

  const handleCloseAlert = () => {
    setAlertPasscodeStatusError(false);
  };

  const handleConfirmReverseLogistic = async passcode_id => {
    const response = await confirmReverseLogistic(passcode_id);
    if (response.status === 200) {
      const responsePasscodes = await getCondoLocksPasscodes(
        lock.id,
        rowsPerPageModal,
        pageModal + 1,
      );
      setPasscodes(responsePasscodes.data.response);
      handleClose();
      toastRef.current.handleOpen(
        'Logística reversa feita com sucesso!',
        'success',
      );
    } else {
      handleClose();
      toastRef.current.handleOpen(
        'Erro ao realizar a logística reversa.',
        'error',
      );
    }
  };

  const handleResetAdminPasscode = async () => {
    const condo = JSON.parse(sessionStorage.getItem('condoCompany'));
    const response = await resetAdminPasscode(
      condo.id,
      controller.controller_id,
    );
    if (response.status === 200) {
      toastRef.current.handleOpen(
        'As senhas de administrador foram atualizadas com sucesso!',
        'success',
      );
    } else {
      toastRef.current.handleOpen(
        'Erro ao atualizar as senhas de administrador.',
        'error',
      );
    }
  };

  useEffect(() => {
    props.handler('Armários');
    const fetchCondoControllers = async () => {
      const response = await getCondoControllers();
      if (response.status === 200) {
        const normalizedControllers = normalizeControllers(response.data);
        const sortedControllers = sortData(
          normalizedControllers,
          'controller_name',
        );
        setControllers(sortedControllers);
      } else {
        toastRef.current.handleOpen(
          'Erro ao recuperar seus armários.',
          'error',
        );
      }
    };
    fetchCondoControllers();
  }, [props]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
      {alertPasscodeStatusError && (
        <Alert
          severity="error"
          onClose={() => {
            handleCloseAlert();
          }}>
          <AlertTitle>Erro</AlertTitle>
          Essa porta já tem uma senha ativa —{' '}
          <strong>Não foi possível ativá-la!</strong>
        </Alert>
      )}

      <Toast ref={toastRef} />

      {loading && <Loading />}

      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{ zIndex: '2000' }}>
        <DialogTitle id="alert-dialog-slide-title">
          {
            'Deseja realizar a logística reversa para esta porta e libera-lá para uso?'
          }
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Senha: {passcode.code}
            <br></br>
            Porta: {lock.name}
            <br></br>
            Moradia: {passcode.habitation_name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{ color: colors.basic_40, fontWeight: 600 }}>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              handleConfirmReverseLogistic(passcode.id);
            }}
            style={{ color: colors.tertiary_40, fontWeight: 600 }}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openStatusChangeDialog}
        onClose={handleCloseStatusChangeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Deseja mesmo alterar o status desta porta?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Não será possível alterar o status da porta caso haja senhas ativas
            ou expiradas para essa porta.
            <br />
            <br />
            A última senha não será desativada nem reativada nessa operação.
            Para isso, ative ou desative a senha no botão de &apos;Senhas&apos;.
            <br />
            <br />
            Confirme abaixo caso queira alterar o status desta porta.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: colors.basic_40, fontWeight: 600 }}
            onClick={handleCloseStatusChangeDialog}>
            Cancelar
          </Button>
          <Button
            style={{ color: colors.tertiary_40, fontWeight: 600 }}
            onClick={acceptStatusChangeDialog}
            autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openPasscodeStatusChangeDialog}
        onClose={closePasscodeStatusChangeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Deseja mesmo alterar o status desta senha?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Confirme abaixo caso queira alterar o status desta senha.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closePasscodeStatusChangeDialog}>Cancelar</Button>
          <Button onClick={acceptPasscodeStatusChangeDialog} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <ModalPasscodes
        modalOpen={modalOpen}
        closeModal={closeModal}
        passcodes={passcodes}
        handlePasscodeStatusChange={handlePasscodeStatusChange}
        lock={lock}
        pageModal={pageModal}
        rowsPerPageModal={rowsPerPageModal}
        handleChangePageModal={handleChangePageModal}
        handleChangeRowsPerPageModal={handleChangeRowsPerPageModal}
        handleClickOpen={handleClickOpen}
      />

      <ModalAdminPasscodes
        openModalAdmin={openModalAdmin}
        handleCloseModalAdmin={handleCloseModalAdmin}
        handleResetAdminPasscode={handleResetAdminPasscode}
      />

      <div>
        <Typography
          style={{
            fontFamily: 'Montserrat',
            fontWeight: 600,
            color: colors.basic_70,
          }}
          variant="h5"
          gutterBottom>
          Selecione o armário
        </Typography>
      </div>

      <FormControl style={{ minWidth: 120 }}>
        {controllers !== '' ? (
          <>
            <InputLabel id="demo-simple-select-label">Armários</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              value={controller}
              onChange={event => handleChangeController(event.target.value)}>
              {controllers.map(controller => (
                <MenuItem key={controller.controller_id} value={controller}>
                  {controller.controller_name.substr(0, 4) === 'Mini'
                    ? controller.controller_name.substr(
                        10,
                        controller.controller_name.length,
                      )
                    : controller.controller_name}
                </MenuItem>
              ))}
            </Select>
          </>
        ) : (
          <div style={{ marginLeft: '40px' }}>
            <Loading />
          </div>
        )}
      </FormControl>

      {details ? (
        <div>
          <Accordion
            style={{
              maxWidth: '500px',
              marginTop: '15px',
              borderRadius: '5px',
            }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                <strong
                  style={{
                    fontFamily: 'Montserrat',
                    fontWeight: 600,
                    color: colors.tertiary_40,
                    fontSize: '1.4rem',
                  }}>
                  Detalhes do Armário
                </strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {details.wifi_rssi && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <strong>WiFi RSSI:</strong>{' '}
                    <span style={{ marginLeft: '4px' }}>
                      {details.wifi_rssi}
                    </span>
                    <div
                      style={{
                        marginLeft: '4px',
                        marginTop: '4px',
                      }}>
                      <Tooltip
                        title="O sinal RSSI representa a qualidade do sinal. É representado por um número negativo e quanto mais perto de 0, melhor. Ex: -25 é um ótimo sinal e -75 é um sinal fraco."
                        placement="top">
                        {details.wifi_rssi >= -25 ? (
                          <SignalWifi4BarIcon
                            style={{
                              color: colors.positive_40,
                              height: '18px',
                            }}
                          />
                        ) : details.wifi_rssi >= -50 ? (
                          <SignalWifi3BarIcon
                            style={{
                              color: colors.positive_20,
                              height: '18px',
                            }}
                          />
                        ) : details.wifi_rssi >= -75 ? (
                          <SignalWifi2BarIcon
                            style={{
                              color: colors.neutral_40,
                              height: '18px',
                            }}
                          />
                        ) : (
                          <SignalWifi1BarIcon
                            style={{
                              color: colors.negative_40,
                              height: '18px',
                            }}
                          />
                        )}
                      </Tooltip>
                    </div>
                  </div>
                )}
                {details.online_since && (
                  <>
                    <strong>Última comunicação: </strong>
                    {formatDateTime(details.online_since)}
                  </>
                )}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      ) : (
        <></>
      )}

      {openRemoteOpeningAlert && (
        <Alert
          style={{ marginTop: '15px' }}
          severity={remoteOpeningResponse?.status === 200 ? 'success' : 'error'}
          onClose={() => setOpenRemoteOpeningAlert(false)}>
          <AlertTitle>Tentativa de abertura remota</AlertTitle>
          Status da reposta:{' '}
          <strong>{` ${remoteOpeningResponse?.status}`}</strong> <br />
          Mensagem recebida:{' '}
          <strong>{` ${remoteOpeningResponse?.message}`}</strong>
        </Alert>
      )}

      <Dialog
        open={openRemoteOpeningDialog}
        onClose={() => setOpenRemoteOpeningDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Deseja mesmo abrir essa porta remotamente?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Confirme abaixo caso queira abrir a porta do armário.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenRemoteOpeningDialog(false)}
            style={{ color: colors.basic_40, fontWeight: 600 }}>
            Cancelar
          </Button>
          <Button
            onClick={handleOpenLock}
            autoFocus
            style={{ color: colors.tertiary_40, fontWeight: 600 }}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <div>
        {locks !== '' ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            <TableContainer component={Paper} style={{ marginTop: '15px' }}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontFamily: 'Montserrat',
                        fontWeight: 600,
                        color: colors.tertiary_40,
                        fontSize: '1.2rem',
                      }}>
                      Nome da porta
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: 'Montserrat',
                        fontWeight: 600,
                        color: colors.tertiary_40,
                        fontSize: '1.2rem',
                      }}>
                      Status da porta
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: 'Montserrat',
                        fontWeight: 600,
                        color: colors.tertiary_40,
                        fontSize: '1.2rem',
                      }}>
                      Número da porta
                    </TableCell>
                    <TableCell
                      style={{
                        color: colors.tertiary_40,
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                      <KeyIcon />
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {locks
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(lock => (
                      <TableRow key={lock.id}>
                        <TableCell style={{ height: 'full' }}>
                          {lock.name}
                          {lock.has_expired_passcode ? (
                            <Tooltip
                              title="Portas em vermelho possuem senhas expiradas e necessitam de realização da logística reversa."
                              placement="top">
                              <ErrorOutlineIcon
                                style={{
                                  color: '#ca2f12',
                                  marginLeft: '5px',
                                  paddingTop: '5px',
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <></>
                          )}
                        </TableCell>
                        <TableCell>
                          <FormControl
                            sx={{
                              m: 1,
                              minWidth: 80,
                            }}>
                            <Select
                              value={lock.status}
                              onChange={event =>
                                handleStatusChange(lock, event.target.value)
                              }>
                              <MenuItem value={'working'}>Livre</MenuItem>
                              <MenuItem value={'reserved'}>Ocupada</MenuItem>
                              <MenuItem value={'maintenance'}>
                                Manuntenção
                              </MenuItem>
                              <MenuItem value={'blocked'}>Bloqueada</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>

                        <TableCell>
                          <Typography
                            style={{
                              textAlign: 'center',
                            }}>
                            {lock.lock_number}
                          </Typography>
                        </TableCell>
                        <TableCell style={{ display: 'flex' }}>
                          <Button
                            onClick={() => {
                              openModal(lock);
                            }}
                            variant="contained"
                            style={
                              lock.has_expired_passcode
                                ? {
                                    backgroundColor: '#ca2f12',
                                    color: 'white',
                                    marginTop: '5px',
                                    marginBottom: '5px',
                                    borderRadius: '10px',
                                  }
                                : {
                                    backgroundColor: colors.tertiary_40,
                                    color: 'white',
                                    marginTop: '5px',
                                    marginBottom: '5px',
                                    borderRadius: '10px',
                                  }
                            }>
                            Senhas
                          </Button>
                        </TableCell>
                        <TableCell>
                          {controller.is_remote && (
                            <Button
                              onClick={() => {
                                setOpenRemoteOpeningDialog(true);
                                setLockIdToBeOpened(lock.id);
                              }}
                              variant="contained"
                              style={{
                                backgroundColor: '#c2c2c2',
                                color: 'black',
                                marginRight: '20px',
                                marginTop: '5px',
                                marginBottom: '5px',
                                borderRadius: '10px',
                              }}>
                              Abrir porta
                            </Button>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={locks.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={event =>
                  handleChangeRowsPerPage(event.target.value)
                }
                labelRowsPerPage={'Portas por página'}
              />
            </TableContainer>

            <Button
              onClick={openAdminModal}
              variant="contained"
              style={{
                backgroundColor: colors.tertiary_40,
                color: 'white',
                alignSelf: 'flex-end',
                justifySelf: 'flex-end',
                marginTop: '20px',
              }}>
              Atualizar Senhas de Administrador
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
