import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import {
  getHabitationPasscodes,
  updatePasscodeStatus,
} from '../../../api/index';
import Toast from '../../../components/Toast';
import { colors } from '../../../styles/colors';

ModalPasscodes.propTypes = {
  close: PropTypes.func,
  setLoading: PropTypes.func,
  habitation_id: PropTypes.number,
  open: PropTypes.bool,
};

export default function ModalPasscodes({
  close,
  setLoading,
  habitation_id,
  open,
}) {
  const toastRef = useRef();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [passcodes, setPasscodes] = useState('');
  const [passcodesTotal, setPasscodesTotal] = useState(0);
  const [passcodeLockId, setPasscodeLockId] = useState('');
  const [passcodeSelectedId, setPasscodeSelectedId] = useState('');
  const [passcodeActivate, setPasscodeActivate] = useState('');
  const [openPasscodeStatusChangeDialog, setOpenPasscodeStatusChangeDialog] =
    useState(false);
  const [alertPasscodeStatusError, setAlertPasscodeStatusError] =
    useState(false);

  const handleChangeRowsPerPage = rowsPerPageInput => {
    setRowsPerPage(parseInt(rowsPerPageInput, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleOpenPasscodeStatusChangeDialog = (
    lockId,
    passcodeId,
    newStatus,
  ) => {
    setPasscodeLockId(lockId);
    setPasscodeSelectedId(passcodeId);
    setPasscodeActivate(newStatus);
    setOpenPasscodeStatusChangeDialog(true);
  };

  const handlePasscodeStatusChange = (lockId, passcodeId, newStatus) => {
    handleOpenPasscodeStatusChangeDialog(lockId, passcodeId, newStatus);
  };

  const closeModal = () => {
    setPage(0);
    setRowsPerPage(10);
    setPasscodes('');
    close();
  };

  const expiringDate = date => {
    const expiringDate = new Date(date);
    expiringDate.setDate(expiringDate.getDate() + 3);
    return expiringDate.toLocaleString('pt-br');
  };

  const handleLoading = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 15000);
  };

  const closePasscodeStatusChangeDialog = () => {
    setOpenPasscodeStatusChangeDialog(false);
  };

  const acceptPasscodeStatusChangeDialog = async () => {
    closePasscodeStatusChangeDialog();
    handleLoading();
    const responseUpdateStatus = await updatePasscodeStatus(
      passcodeLockId,
      passcodeSelectedId,
      passcodeActivate,
    );
    if (responseUpdateStatus.data.status === 200) {
      const offset = page + 1,
        limit = rowsPerPage;
      const responseHabitationPasscodes = await getHabitationPasscodes(
        habitation_id,
        limit,
        offset,
      );
      setPasscodes(responseHabitationPasscodes.data.result.passcodes);
    } else {
      setAlertPasscodeStatusError(true);
      closeModal();
    }
    setLoading(false);
  };

  const handleCloseAlert = () => {
    setAlertPasscodeStatusError(false);
  };

  useEffect(() => {
    const handleGetHabitationPasscodes = async habitation_id => {
      const offset = page + 1,
        limit = rowsPerPage;
      const response = await getHabitationPasscodes(
        habitation_id,
        limit,
        offset,
      );
      if (response.status === 200) {
        setPasscodes(response.data.result.passcodes);
        setPasscodesTotal(response.data.result.total);
      } else {
        toastRef.current.handleOpen(
          'Erro ao recuperar senhas dessa moradia.',
          'error',
        );
      }
    };
    if (habitation_id) {
      handleGetHabitationPasscodes(habitation_id);
    }
  }, [page, rowsPerPage, habitation_id]);

  return (
    <>
      {alertPasscodeStatusError && (
        <Alert
          severity="error"
          onClose={() => {
            handleCloseAlert();
          }}>
          <AlertTitle>Erro</AlertTitle>
          Essa porta já tem uma senha ativa —{' '}
          <strong>Não foi possível ativá-la!</strong>
        </Alert>
      )}

      <Toast ref={toastRef} />

      <Dialog
        open={openPasscodeStatusChangeDialog}
        onClose={closePasscodeStatusChangeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Deseja mesmo alterar o status desta senha?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            A reativação só funcionará se a porta não tiver senha ativa.
            <br />A desativação de uma senha ativa irá liberar a porta. <br />
            <br />
            Confirme abaixo caso queira alterar o status desta senha.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: colors.basic_40, fontWeight: 600 }}
            onClick={closePasscodeStatusChangeDialog}>
            Cancelar
          </Button>
          <Button
            style={{ color: colors.tertiary_40, fontWeight: 600 }}
            onClick={acceptPasscodeStatusChangeDialog}
            autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={open}
        onClose={closeModal}>
        <div
          style={{
            backgroundColor: 'white',
            minWidth: '200px',
            minHeight: '200px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'space-around',
            padding: '10px',
          }}>
          {passcodes !== '' ? (
            <div
              style={{
                backgroundColor: 'white',
                maxHeight: '575px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-around',
                padding: '10px',
              }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 600 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          fontFamily: 'Montserrat',
                          fontWeight: 600,
                          color: colors.tertiary_40,
                          fontSize: '1.2rem',
                        }}>
                        Senha
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontFamily: 'Montserrat',
                          fontWeight: 600,
                          color: colors.tertiary_40,
                          fontSize: '1.2rem',
                        }}>
                        Porta
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: 'Montserrat',
                          fontWeight: 600,
                          color: colors.tertiary_40,
                          fontSize: '1.2rem',
                        }}>
                        Data de Criação
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: 'Montserrat',
                          fontWeight: 600,
                          color: colors.tertiary_40,
                          fontSize: '1.2rem',
                        }}>
                        Data de Expiração
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          fontFamily: 'Montserrat',
                          fontWeight: 600,
                          color: colors.tertiary_40,
                          fontSize: '1.2rem',
                        }}>
                        Ativa
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {passcodes !== '' ? (
                      passcodes.map(passcode => (
                        <TableRow
                          key={passcode.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}>
                          <TableCell align="left">{passcode.code}</TableCell>
                          <TableCell align="left">
                            {passcode.lock_name}
                          </TableCell>
                          <TableCell align="center">
                            {new Date(passcode.created_at).toLocaleString(
                              'pt-br',
                            )}
                          </TableCell>
                          <TableCell>
                            {expiringDate(passcode.created_at)}
                          </TableCell>
                          <TableCell align="center">
                            <FormControl
                              sx={{
                                m: 1,
                                minWidth: 80,
                              }}>
                              <Select
                                value={
                                  passcode.expired
                                    ? 'expired'
                                    : passcode.is_active
                                }
                                onChange={event =>
                                  handlePasscodeStatusChange(
                                    passcode.lock_id,
                                    passcode.id,
                                    event.target.value,
                                  )
                                }
                                style={
                                  passcode.expired
                                    ? {
                                        color: colors.negative_40,
                                      }
                                    : {
                                        color: colors.black,
                                      }
                                }>
                                <MenuItem value={true}>Ativa</MenuItem>
                                <MenuItem value={false}>Desativada</MenuItem>
                                <MenuItem value="expired" disabled>
                                  Expirada
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <></>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[5, 10, 25]}
                  count={passcodesTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={event =>
                    handleChangeRowsPerPage(event.target.value)
                  }
                  labelRowsPerPage={'Senhas por página'}
                />
              </TableContainer>

              <Button
                onClick={() => {
                  closeModal();
                }}
                variant="contained"
                style={{
                  backgroundColor: colors.white,
                  color: colors.tertiary_40,
                  marginTop: '1rem',
                  border: `1px solid ${colors.tertiary_40}`,
                  borderRadius: '10px',
                }}>
                Fechar
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Modal>
    </>
  );
}
