import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { colors } from '../../../styles/colors';
import { formatCellphone } from '../../../utils/formatCellphone';

ModalHabitation.propTypes = {
  habitation: PropTypes.object,
  index: PropTypes.number,
  openPasscodesModal: PropTypes.func,
  openModal: PropTypes.func,
  handleClickOpen: PropTypes.func,
};

export default function ModalHabitation({
  habitation,
  index,
  openPasscodesModal,
  openModal,
  handleClickOpen,
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          {habitation.id}
        </TableCell>
        <TableCell align="center">{habitation.name}</TableCell>
        <TableCell align="center">
          {formatCellphone(habitation.cellphone)}
        </TableCell>
        <TableCell align="center">{habitation.email}</TableCell>
        <TableCell align="center">
          <Button
            onClick={() => {
              openPasscodesModal(habitation);
            }}
            variant="contained"
            style={{
              backgroundColor: colors.tertiary_40,
              color: 'white',
              marginTop: '5px',
              marginBottom: '5px',
              borderRadius: '10px',
            }}>
            Senhas
          </Button>
        </TableCell>
        <TableCell>
          <Button
            onClick={() => {
              openModal(habitation, index);
            }}
            variant="contained"
            style={{
              backgroundColor: '#e9e9e9',
              color: 'black',
              marginTop: '5px',
              marginBottom: '5px',
              borderRadius: '10px',
            }}>
            Editar
          </Button>
        </TableCell>
        <TableCell>
          <Button
            onClick={() => {
              handleClickOpen(habitation);
            }}
            variant="contained"
            style={{
              backgroundColor: 'white',
              color: colors.tertiary_40,
              marginTop: '5px',
              marginBottom: '5px',
              border: `1px solid ${colors.tertiary_40}`,
              borderRadius: '10px',
            }}>
            Deletar
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="integration">
                <TableHead>
                  <TableRow>
                    <TableCell
                      variant="h6"
                      gutterBottom
                      component="div"
                      style={{
                        fontFamily: 'Montserrat',
                        fontWeight: 500,
                        fontSize: '1.2rem',
                      }}>
                      Informações de integração
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: 'Montserrat',
                        fontWeight: 500,
                        fontSize: '0.9rem',
                      }}>
                      ID Externo
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: 'Montserrat',
                        fontWeight: 500,
                        fontSize: '0.9rem',
                      }}>
                      UUID Externo
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell />
                    <TableCell component="th" scope="row">
                      {habitation.external_id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {habitation.external_uuid}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
