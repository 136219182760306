export const patchNotes = [
  {
    id: 9,
    title: '🎉 Notas de atualização v2.3 - 28/12/2024',
    text: 'Nova funcionalidade: agora é possível buscar termos em eventos do histórico de uso! Além disso, também é possível ordenar as colunas desejadas clicando no nome da coluna que deseja ordenar.',
  },
  {
    id: 8,
    title: 'Notas de atualização v2.2 - 25/09/2024',
    text: 'Ajustes: agora não será possível mudar o status de uma porta caso haja senhas ativas ou expiradas.',
  },
  {
    id: 7,
    title: 'Notas de atualização v2.1 - 22/05/2024',
    text: 'Nova funcionalidade: filtro de categorias no histórico de uso. O filtro permite visualizar somente o histórico de uso desejado. Lockin são ações do armário e entrega. Correios são ações relacionadas à Caixa de Correio Inteligente dos Correios. App são ações específicas de acesso no aplicativo do condomínio.',
  },
  {
    id: 6,
    title: 'Notas de atualização v2.0 - 27/02/2024',
    text: 'A primeira atualização do ano está repleta de detalhes para tornar a sua experiência mais agradável e padronizar o estilo dos componentes visuais! Esperamos que gostem e estamos abertos a todo tipo de feedback, que pode ser feito pelo contato na parte inferior desta página!',
  },
  {
    id: 5,
    title: 'Notas de atualização v1.5.5 - 26/04/2023',
    text: "Nova funcionalidade: entrega remota! Ao selecionar 'Criar Entrega' no menu lateral, o usuário é redirecionado para o preenchimento dos dados da entrega. Após selecionar o armário, o bloco, a moradia e o tamanho de porta, iremos abrir remotamente o armário para que o entregador deposite a encomenda. O morador receberá a senha de retirada normalmente.",
  },
  {
    id: 4,
    title: 'Notas de atualização v1.5.3 - 11/04/2023',
    text: 'Novidade: As telas de Armários e Moradias foram atualizadas para tornar a plataforma mais fácil, prática e amigável para todos usarem!',
  },
  {
    id: 3,
    title: 'Notas de atualização v1.5.1 - 14/12/2022',
    text: 'Estamos de cara nova! Estamos começando a melhorar nosso layout. Estamos buscando deixar nossa plataforma mais fácil, prática e amigável para todos usarem!',
  },
  {
    id: 2,
    title: 'Notas de atualização v1.4.1 - 14/11/2022',
    text: "Nova funcionalidade: agora é possível performar aberturas remotas de portas caso essa funcionalidade esteja habilitada para seu armário. Para isso, clique em 'Armários', selecione o armário desejado, clique em 'Abrir porta' na porta de interesse. Se o botão 'Abrir porta' não aparece, esse armário não permite aberturas remotas.",
  },
  {
    id: 1,
    title: 'Notas de atualização v1.4 - 20/10/2022',
    text: "Nova funcionalidade: agora é possível atualizar todas as senhas de administrador de um armário. Para isto, clique em 'Armários', selecione o armário desejado e no final da tabela de portas do armário clique em 'Atualizar senhas de administrador'.",
  },
];
